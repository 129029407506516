import { useCallback } from "react";

import { useFeatureFlag } from "common/feature_gating";
import { useSignTransactionsEnabled } from "util/feature_detection";
import { getProofRequirement } from "common/transactions/new";
import { transactionEditRoute } from "util/routes";
import { PROOF_TRANSACTIONS } from "constants/feature_gates";
import {
  PROOF_TRANSACTION_TYPE,
  ESIGN_TRANSACTION_TYPE,
  IDENTIFY_TRANSACTION_TYPE,
  VERIFY_TRANSACTION_TYPE,
} from "constants/transaction";
import {
  MortgageTransactionType,
  OrganizationTypeEnum,
  SigningRequirementEnum,
} from "graphql_globals";
import { DEFAULT_VERIFY_CONFIGURATION } from "common/dashboard/easy_link/verify/common";

import {
  getTransactionTypeFromParam,
  useCreateTransaction,
  type CreateTransactionReturnValue,
  type GetEditRoute,
  type GetErrorRoute,
  type GetInputVariables,
  type Organization,
  type RecipientInput,
} from "./use-create-transaction";

export type { CreateTransactionReturnValue, Organization };
export { getTransactionTypeFromParam };

type Props = {
  getErrorRoute?: GetErrorRoute;
  recipient?: RecipientInput;
};

const getEditRoute: GetEditRoute = ({ transactionType, transaction }) => {
  return transactionEditRoute({
    id: transaction.id,
    type:
      transactionType === ESIGN_TRANSACTION_TYPE
        ? "esign"
        : transactionType === PROOF_TRANSACTION_TYPE
          ? "proof"
          : transactionType === IDENTIFY_TRANSACTION_TYPE
            ? "identify"
            : transactionType === VERIFY_TRANSACTION_TYPE
              ? "verify"
              : undefined,
  });
};

const getTransactionTypeForOrganization = ({
  organization,
  transactionType,
  requiresNotaryMeeting,
}: Parameters<GetInputVariables>[0] & { requiresNotaryMeeting: boolean }) => {
  const { organizationType } = organization;
  if (
    organizationType === OrganizationTypeEnum.TITLE_AGENCY ||
    organizationType === OrganizationTypeEnum.LENDER
  ) {
    // we are supporting real estate versions of esign and proof for title
    // so that they can add contacts/recall etc.
    if (transactionType === ESIGN_TRANSACTION_TYPE) {
      return MortgageTransactionType.real_estate_esign;
    } else if (transactionType === PROOF_TRANSACTION_TYPE) {
      return MortgageTransactionType.real_estate_proof;
    }
  }
  if (!requiresNotaryMeeting) {
    return null;
  }
  return MortgageTransactionType.other;
};

export function useCreateRealEstateTransaction({ getErrorRoute, recipient }: Props = {}) {
  const proofFlagEnabled = useFeatureFlag(PROOF_TRANSACTIONS);
  const signTransactionsEnabled = useSignTransactionsEnabled();

  const getInputVariables: GetInputVariables = useCallback(
    ({ transactionType, organization }) => {
      const isEsignTransaction = transactionType === ESIGN_TRANSACTION_TYPE;
      const isProofTransaction = transactionType === PROOF_TRANSACTION_TYPE;
      const isIdentifyTransaction = transactionType === IDENTIFY_TRANSACTION_TYPE;
      const isVerifyTransaction = transactionType === VERIFY_TRANSACTION_TYPE;

      const requiresNotaryMeeting =
        transactionType !== ESIGN_TRANSACTION_TYPE &&
        transactionType !== PROOF_TRANSACTION_TYPE &&
        transactionType !== IDENTIFY_TRANSACTION_TYPE &&
        transactionType !== VERIFY_TRANSACTION_TYPE;

      const proofFeaturesEnabled = proofFlagEnabled
        ? isEsignTransaction
          ? signTransactionsEnabled
          : isProofTransaction || isIdentifyTransaction
        : false;

      const defaultCustomer: RecipientInput = {
        ...(isVerifyTransaction && { signingRequirement: SigningRequirementEnum.VERIFY }),
        ...(isVerifyTransaction && { verifyToolConfiguration: DEFAULT_VERIFY_CONFIGURATION }),
        ...((isEsignTransaction || isProofTransaction) && {
          signingRequirement: SigningRequirementEnum.ESIGN,
        }),
        ...(proofFeaturesEnabled && { proofRequirement: getProofRequirement(organization) }),
        ...recipient,
      };

      return {
        transaction: {
          // isMortgage only needs to be true when creating a RON transaction since esign/proof and
          // real_estate_esign/real_estate_proof are handled properly by isMortgage: false
          isMortgage: requiresNotaryMeeting,
          transactionType: getTransactionTypeForOrganization({
            transactionType,
            organization,
            requiresNotaryMeeting,
          }),
        },
        customers: [defaultCustomer],
      };
    },
    [
      proofFlagEnabled,
      recipient,
      signTransactionsEnabled,
      getProofRequirement,
      getTransactionTypeForOrganization,
    ],
  );

  return useCreateTransaction({ getEditRoute, getErrorRoute, getInputVariables });
}
