import { useCallback } from "react";

import { useFeatureFlag } from "common/feature_gating";
import { useSignTransactionsEnabled } from "util/feature_detection";
import { getProofRequirement } from "common/transactions/new";
import { SigningRequirementEnum } from "graphql_globals";
import {
  businessTransactionEditRoute,
  businessEsignTransactionEditRoute,
  businessProofTransactionEditRoute,
  businessIdentifyTransactionEditRoute,
  businessVerifyTransactionEditRoute,
} from "util/routes";
import { PROOF_TRANSACTIONS } from "constants/feature_gates";
import {
  PROOF_TRANSACTION_TYPE,
  ESIGN_TRANSACTION_TYPE,
  IDENTIFY_TRANSACTION_TYPE,
  VERIFY_TRANSACTION_TYPE,
} from "constants/transaction";
import { DEFAULT_VERIFY_CONFIGURATION } from "common/dashboard/easy_link/verify/common";

import {
  getTransactionTypeFromParam,
  useCreateTransaction,
  type CreateTransactionReturnValue,
  type GetEditRoute,
  type GetErrorRoute,
  type GetInputVariables,
  type Organization,
  type RecipientInput,
} from "./use-create-transaction";

export type { CreateTransactionReturnValue, Organization };
export { getTransactionTypeFromParam };

type Props = {
  getErrorRoute?: GetErrorRoute;
  recipient?: RecipientInput;
};

const getEditRoute: GetEditRoute = ({ transactionType, transaction }) => {
  switch (transactionType) {
    case PROOF_TRANSACTION_TYPE:
      return businessProofTransactionEditRoute(transaction);
    case ESIGN_TRANSACTION_TYPE:
      return businessEsignTransactionEditRoute(transaction);
    case IDENTIFY_TRANSACTION_TYPE:
      return businessIdentifyTransactionEditRoute(transaction);
    case VERIFY_TRANSACTION_TYPE:
      return businessVerifyTransactionEditRoute(transaction);
    default:
      return businessTransactionEditRoute(transaction);
  }
};

export function useCreateBusinessTransaction({ getErrorRoute, recipient }: Props = {}) {
  const proofFlagEnabled = useFeatureFlag(PROOF_TRANSACTIONS);
  const signTransactionsEnabled = useSignTransactionsEnabled();

  const getInputVariables: GetInputVariables = useCallback(
    ({ transactionType, organization }) => {
      const isEsignTransaction = transactionType === ESIGN_TRANSACTION_TYPE;
      const isProofTransaction = transactionType === PROOF_TRANSACTION_TYPE;
      const isIdentifyTransaction = transactionType === IDENTIFY_TRANSACTION_TYPE;
      const isVerifyTransaction = transactionType === VERIFY_TRANSACTION_TYPE;

      const proofFeaturesEnabled = proofFlagEnabled
        ? isEsignTransaction
          ? signTransactionsEnabled
          : isProofTransaction || isIdentifyTransaction
        : false;

      const defaultCustomer: RecipientInput = {
        ...(isVerifyTransaction && { signingRequirement: SigningRequirementEnum.VERIFY }),
        ...(isVerifyTransaction && { verifyToolConfiguration: DEFAULT_VERIFY_CONFIGURATION }),
        ...((isEsignTransaction || isProofTransaction) && {
          signingRequirement: SigningRequirementEnum.ESIGN,
        }),
        ...(proofFeaturesEnabled && { proofRequirement: getProofRequirement(organization) }),
        ...recipient,
      };

      return {
        transaction: {},
        customers: [defaultCustomer],
      };
    },
    [proofFlagEnabled, recipient, signTransactionsEnabled, getProofRequirement],
  );

  return useCreateTransaction({ getEditRoute, getErrorRoute, getInputVariables });
}
