import "./deprecated_customer_email.scss";

import classnames from "classnames";
import { useCallback, useState, type ChangeEvent } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import Icon from "common/core/icon";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { DeprecatedStyledTextInput } from "common/form/inputs/text";
import { DeprecatedStyledTextareaInput } from "common/form/inputs/textarea";
import { captureException } from "util/exception";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { isEmail } from "util/string";
import { SensitiveValue } from "common/details/identity";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { DescriptionListItem } from "common/core/description_list";
import { LinkStyledButton } from "common/core/link/link_styled_button";

import SetSignerEmailMutation from "./set_signer_email_mutation.graphql";

const ERRORS = {
  accountHasSensitiveInformation: "account_has_sensitive_information",
  userExists: "user_with_email_already_exists",
  signerInteractedWithTransaction: "current_signer_has_interacted_with_transaction",
  descriptionTooLong: "description_is_too_long",
  emptyFormError: "empty_form_error",
  invalidEmail: "invalid_email",
  sameEmail: "current_signer_already_has_given_email",
};

const MESSAGES = defineMessages({
  placeholder: {
    id: "93f9170f-a566-41ae-99dc-2cca4344a43f",
    defaultMessage: "Signer Email",
  },
  descriptionPlaceholder: {
    id: "c478a5c3-c4b7-42c1-836b-ed70d77ef7b9",
    defaultMessage: "Who requested this change?",
  },
  emailAddress: {
    id: "db1ae6eb-be2c-4c2e-8835-e5cd0234894d",
    defaultMessage: "Email address",
  },
  editEmailLabel: {
    id: "42407af5-65a6-4355-bb2e-15d9448e6848",
    defaultMessage: "Edit",
  },
  [ERRORS.accountHasSensitiveInformation]: {
    id: "10f88bad-dec8-4aeb-9318-b5b1ef7c9bc8",
    defaultMessage:
      "<span>Your attempt to edit the email address on this transaction has been unsuccessful for one or more of the following reasons:{br}" +
      "<li>The email address <u>that’s already listed</u> for this transaction signer is associated with an existing user login. </li>" +
      "<li>The email address <u>that’s already listed</u>  for this transaction signer belongs to an existing user that’s associated with an organization account (Business, Title, or Lender).</li>" +
      "<li>The email address <u>that’s already listed</u> for this transaction signer is associated with a saved payment method.</li>" +
      "<li>The email address <u>that’s already listed</u> for this transaction signer belongs to an existing user that has been sent one or more additional transactions (not including drafts).</li>{br}" +
      "<b>If the signer user expects/is expected to use a different email address to access their account and/or all the transactions it currently contains</b>, the user can make this change directly by logging into their account to edit the email address in their profile settings.{br}" +
      "<b>If the current signer user associated with this transaction is not the correct individual</b>, the organization who owns this transaction will need to recall the transaction, make changes to the transaction’s signer details, and re-send it.{br}" +
      "<b>If none of the above is applicable</b>, you may try again using a different email address.</span>",
  },
  [ERRORS.userExists]: {
    id: "bce0cf4f-b669-49bd-a42b-e513d5eb0a49",
    defaultMessage: "A user with that email already exists. Do you want to continue?",
  },
  [ERRORS.signerInteractedWithTransaction]: {
    id: "7cbec634-9fcd-4f34-9b10-dfd65b700ce4",
    defaultMessage: "The signer has already signed something on the transaction.",
  },
  [ERRORS.descriptionTooLong]: {
    id: "79962f1d-88bd-487a-b717-dba3b78db71e",
    defaultMessage: "The description must be less than 256 characters.",
  },
  [ERRORS.emptyFormError]: {
    id: "9a4f45c0-eb12-4e60-b857-4f8d5c53a70b",
    defaultMessage: "The signer email and description must be filled out.",
  },
  [ERRORS.invalidEmail]: {
    id: "7eddc124-cc16-46ad-a657-00b273c501ce",
    defaultMessage: "The email is invalid.",
  },
  [ERRORS.sameEmail]: {
    id: "cf403895-f9df-45cc-91e2-2f673597512d",
    defaultMessage: "The signer already has the email you inputted.",
  },
  genericError: {
    id: "b613c303-4f43-488c-ae4e-604efebaadbb",
    defaultMessage: "There was an error updating customer email, please #ask-engineering",
  },
});

type Props = {
  email: string;
  canEdit?: boolean;
  transactionCustomerId?: string;
  onUpdate: () => void;
  hideSensitiveData?: boolean;
};

export default function CustomerEmail({
  email,
  canEdit,
  transactionCustomerId,
  onUpdate,
  hideSensitiveData,
}: Props) {
  const intl = useIntl();
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const [showEditForm, setShowEditForm] = useState(false);
  const [newSignerEmail, updateNewSignerEmail] = useState("");
  const [description, updateDescription] = useState("");
  const [emailError, updateEmailError] = useState<string | null>(null);
  const [isLoading, updateIsLoading] = useState(false);

  const setSignerEmailMutation = useMutation(SetSignerEmailMutation);

  const clearForm = () => {
    setShowEditForm(false);
    updateNewSignerEmail("");
    updateDescription("");
    updateEmailError(null);
  };

  const setSignerEmail = useCallback(
    (shouldUpdateExistingUser: boolean) => {
      if (newSignerEmail.length === 0 || description.length === 0) {
        updateEmailError(ERRORS.emptyFormError);
        return;
      } else if (!isEmail(newSignerEmail)) {
        updateEmailError(ERRORS.invalidEmail);
        return;
      }
      updateEmailError(null);
      updateIsLoading(true);
      return setSignerEmailMutation({
        variables: {
          input: {
            transactionCustomerId: transactionCustomerId!,
            email: newSignerEmail,
            updateExistingUser: shouldUpdateExistingUser,
            description,
          },
        },
      })
        .then(() => {
          clearForm();
          return onUpdate();
        })
        .catch((error: Error) => {
          if (!isGraphQLError(error)) {
            captureException(error);
          }
          const err = isGraphQLError(error) ? error.graphQLErrors[0] : error;
          updateEmailError(err.message);
        })
        .finally(() => {
          updateIsLoading(false);
        });
    },
    [newSignerEmail, description, transactionCustomerId, email, onUpdate],
  );

  if (!email) {
    return null;
  }

  // backend can send an error that we don't recognize, if so, we should fail gracefully with a generic error message
  const errorMessage =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    emailError && MESSAGES[emailError]
      ? intl.formatMessage(MESSAGES[emailError], {
          b: (word) => <strong>{word}</strong>,
          br: <br />,
          li: (word) => <li>{word}</li>,
          span: (word) => <span>{word}</span>,
          u: (word) => <u>{word}</u>,
        })
      : emailError
        ? intl.formatMessage(MESSAGES.genericError)
        : null;

  const emailLabel = intl.formatMessage(MESSAGES.emailAddress);
  const emailContent = (
    <div data-automation-id="customer-email">
      {hideSensitiveData ? (
        <SensitiveValue
          obfuscatedValue={`${email.substring(0, 4)}****${email.substring(email.indexOf("@"), email.length)}`}
          value={email}
        />
      ) : (
        email
      )}
    </div>
  );
  const editEmailButton = canEdit && transactionCustomerId && (
    <LinkStyledButton
      className={classnames(!isTxnDetailsRedesign && "CustomerEmail--button")}
      underlined={false}
      onClick={() => {
        setShowEditForm(true);
      }}
      aria-label={intl.formatMessage(MESSAGES.editEmailLabel)}
    >
      {isTxnDetailsRedesign ? <Icon name="pencil" /> : intl.formatMessage(MESSAGES.editEmailLabel)}
    </LinkStyledButton>
  );

  return (
    <>
      {isTxnDetailsRedesign ? (
        <DescriptionListItem
          term={
            <>
              {emailLabel} {editEmailButton}
            </>
          }
          definition={emailContent}
        />
      ) : (
        <DeprecatedDetailGridSection>
          <DeprecatedDetailGridRow title={emailLabel}>
            <div className="CustomerEmail">
              <div className="CustomerEmail--text">{emailContent}</div>
              {editEmailButton}
            </div>
          </DeprecatedDetailGridRow>
        </DeprecatedDetailGridSection>
      )}
      {showEditForm && (
        <WorkflowModal
          large
          title={
            <FormattedMessage
              id="c605dca5-2a19-49c3-93e0-d9f145dbe5fa"
              defaultMessage="Update Signer Email"
            />
          }
          buttons={[
            <Button
              key="CustomerEmail-cancel"
              variant="tertiary"
              buttonColor="dark"
              onClick={clearForm}
              automationId="cancel-button"
              disabled={isLoading}
            >
              <FormattedMessage id="6f0df639-5dbc-441b-91b7-89564b88b9ff" defaultMessage="Cancel" />
            </Button>,
            <Button
              key="CustomerEmail-agree"
              buttonColor="danger"
              variant="primary"
              onClick={() => {
                setSignerEmail(false);
              }}
              isLoading={isLoading}
              automationId="agree-button"
            >
              <FormattedMessage
                id="7e525ab4-2f7c-4e2f-b4cf-b749e3216675"
                defaultMessage="Accept & Change"
              />
            </Button>,
          ]}
        >
          <>
            <FormattedMessage
              id="5b0195b8-f932-4d06-943c-38dd87fa7860"
              defaultMessage="You are about to change the email address for {email}. Please take note of the following:"
              values={{ email }}
            />
            <ul className="CustomerEmail--list">
              <li>
                <FormattedMessage
                  id="ba1fbcb0-6e0f-4aab-bdc3-a0faf2328a17"
                  defaultMessage="You can only do this change for a signer who has not signed any of the documents yet."
                />
              </li>
              <li>
                <FormattedMessage
                  id="4ed97c28-3c73-4487-8aea-a7fdf9dceb5a"
                  defaultMessage="Do not switch the email to a signer that has already finished signing. The app will not let them sign and you'll have to change the email again."
                />
              </li>
              <li>
                <FormattedMessage
                  id="fa33a5e1-e30a-4e15-833b-7de2defd6cbd"
                  defaultMessage="The desired email can only be accepted from authorized sources such as title agents or closers. Signers are not allowed to ask for these changes."
                />
              </li>
              <li>
                <FormattedMessage
                  id="a20c51dd-38b2-4a0b-84d6-ea313bfb4af9"
                  defaultMessage="Warning: If the original email belongs to a user and the new email does not have a user, the new email will become the user's login and all of their existing transactions will be moved to the new email."
                />
              </li>
              <li>
                <FormattedMessage
                  id="598895dd-136a-4413-be60-e4a398cacaac"
                  defaultMessage="Do not use this feature if the signers are colocated and you want to change the email of the signer who owns the email. This will not work, instead please recreate the transaction or file an incident."
                />
              </li>
            </ul>
            {emailError && (
              <div className="CustomerEmail--error">
                {errorMessage}
                {emailError === ERRORS.userExists && (
                  <div
                    className="CustomerEmail--confirmation"
                    onClick={() => {
                      setSignerEmail(true);
                    }}
                  >
                    <FormattedMessage
                      id="b3cb5d37-4436-48f4-af20-e15dcf107913"
                      defaultMessage=" Yes"
                    />
                  </div>
                )}
              </div>
            )}
            <div className="CustomerEmail--form">
              <DeprecatedStyledTextInput
                value={newSignerEmail}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateNewSignerEmail(e.target.value);
                }}
                placeholder={intl.formatMessage(MESSAGES.placeholder)}
                placeholderAsLabel
              />
            </div>
            <div className="CustomerEmail--form">
              <DeprecatedStyledTextareaInput
                value={description}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateDescription(e.target.value);
                }}
                placeholder={intl.formatMessage(MESSAGES.descriptionPlaceholder)}
              />
            </div>
          </>
        </WorkflowModal>
      )}
    </>
  );
}
