import { useCallback, useMemo, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { useQuery } from "util/graphql";
import { DashboardActionBar } from "common/dashboard";
import EmptyView from "common/dashboard/empty_view/empty";
import { useDebouncedQuery, useFilter } from "common/dashboard/filter";
import { serializerFactory } from "common/dashboard/filter/serializers";
import TablePagination, { usePagination } from "common/core/table/pagination";
import TableSearch from "common/core/table/search";
import LoadingIndicator from "common/core/loading_indicator";
import { Paragraph } from "common/core/typography";
import {
  BulkActionClearButton,
  BulkActionSeparator,
  useBulkActions,
} from "common/core/bulk_actions/common";
import { BulkTable } from "common/core/table";
import { useSettingProfiles } from "util/feature_detection";
import { BulkActionBar } from "common/core/bulk_actions";

import CompanyOrgPaginatedQuery, {
  type CompanyOrgsPaginated_node_Organization as Organization,
  type CompanyOrgsPaginated_node_Organization_appliedSettingProfiles as AppliedSettingProfileType,
} from "./graphql/company_orgs_paginated.graphql";
import { OrganizationDetailsModal } from "../modals/organization_details_modal";
import { getTableColumns } from "./columns";
import { CompanyOrgTable, type OrgTableItem } from "./table";
import Styles from "./index.module.scss";
import { BulkEditProfileButton } from "./edit_profile/bulk_edit_profile_button";

const PAGE_SIZE = 50;

const MESSAGES = defineMessages({
  searchPlaceholder: {
    id: "205d86b8-68e2-4784-8d3f-37ccda317273",
    defaultMessage: "Filter by organization name or ID",
  },
  searchLabel: {
    id: "582d515a-6cc5-43a6-ac3f-c824c8db99e1",
    defaultMessage: "Filter by name or ID",
  },
  brand: {
    id: "bfe9459b-6203-45ff-8bfb-ea922ecfdc2c",
    defaultMessage: "brand",
  },
});

export type OrgTablePaginationItem = OrgTableItem & {
  appliedSettingProfiles: AppliedSettingProfileType[];
};

function orgStructureDeserializer(queryArgs: URLSearchParams) {
  const page = Number(queryArgs.get("page"));
  const query = queryArgs.get("query");

  return { page, query };
}

const orgStructureSerializeMap = {
  page: (arg: number | null) => (arg ? String(arg) : null),
  query: (arg: string | null) => arg || null,
};
const orgStructureSerializer = serializerFactory(orgStructureSerializeMap);

function CompanyOrgBulkTable({
  items,
  mainOrg,
  flattenTree,
  setCurrentOrgId,
}: {
  items: OrgTablePaginationItem[];
  mainOrg: Organization;
  flattenTree: boolean;
  setCurrentOrgId: (id: string) => void;
}) {
  const intl = useIntl();
  const {
    toggleItem,
    toggleAllItems,
    selectedItemIdsSet,
    selectAllCheckboxState,
    selectedItemCount,
    clearAllItems,
    selectedItems,
  } = useBulkActions(items);

  return (
    <>
      {selectedItemCount > 0 && (
        <>
          <BulkActionBar itemCount={selectedItemCount}>
            <BulkActionClearButton onClick={clearAllItems} />
            <BulkActionSeparator />
            <BulkEditProfileButton
              profileType={intl.formatMessage(MESSAGES.brand)}
              selectedItems={selectedItems}
              availableProfiles={mainOrg.settingProfiles}
            />
          </BulkActionBar>
        </>
      )}
      <div className={Styles.tableContainer}>
        <BulkTable
          data={items}
          columns={getTableColumns(flattenTree, true, mainOrg.settingProfiles)}
          totalItems={items.length}
          rowInteraction={{
            onClick: (e) => {
              setCurrentOrgId(e.id);
            },
          }}
          toggleItem={toggleItem}
          toggleAllItems={toggleAllItems}
          selectAllCheckboxState={selectAllCheckboxState}
          selectedItemIds={selectedItemIdsSet}
          stickyHotDogMenu
          noShadow
        />
      </div>
    </>
  );
}

function OrgStructureWithPagination({ organization }: { organization: Organization }) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [currentOrgId, setCurrentOrgId] = useState<string | null>(null);
  const organizations = organization.companyOrganizations;
  const { totalCount } = organizations;
  const pageCount = Math.max(Math.ceil(totalCount / PAGE_SIZE), 1);
  const items = useMemo(() => organizations.edges.map(({ node }) => node), [organizations]);

  const { handleChange, deserializedArgs } = useFilter(
    orgStructureDeserializer,
    orgStructureSerializer,
  );

  const { page: pageIndex, query } = deserializedArgs;
  const { textFilterValue, handleTextFilterChange } = useDebouncedQuery(handleChange, query, true);

  const setPageIndex = useCallback(
    (page: number) => {
      handleChange({ page });
      setLoading(true);
    },
    [handleChange],
  );

  const { canNextPage, canPreviousPage, nextPage, previousPage, startIndex, endIndex } =
    usePagination({
      disabled: loading,
      pageIndex,
      pageCount,
      pageSize: PAGE_SIZE,
      items,
      onPageChange: setPageIndex,
    });

  const settingProfilesFlag = useSettingProfiles();
  const flattenTree = Boolean(query);

  return (
    <>
      <TableSearch
        value={textFilterValue}
        placeholder={intl.formatMessage(MESSAGES.searchPlaceholder)}
        aria-label={intl.formatMessage(MESSAGES.searchLabel)}
        onChange={handleTextFilterChange}
      />
      <DashboardActionBar
        hideExportButton
        pagination={
          <TablePagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            previousPage={previousPage}
            startIndex={startIndex}
            endIndex={endIndex}
            totalCount={totalCount}
          />
        }
      />

      {currentOrgId && (
        <OrganizationDetailsModal
          mainOrgId={organization.id}
          organizationId={currentOrgId}
          onCancel={() => setCurrentOrgId(null)}
        />
      )}

      {items.length ? (
        settingProfilesFlag ? (
          <CompanyOrgBulkTable
            items={items}
            mainOrg={organization}
            flattenTree={flattenTree}
            setCurrentOrgId={setCurrentOrgId}
          />
        ) : (
          <CompanyOrgTable
            items={items}
            setCurrentOrgId={setCurrentOrgId}
            flattenTree={flattenTree}
          />
        )
      ) : (
        <EmptyView
          title={{ text: "No results found", headingLevel: "h4", headingStyle: "headingFour" }}
        >
          <Paragraph>
            <FormattedMessage
              id="63309012-a738-4752-8ff2-66b775620b65"
              defaultMessage="Try adjusting your search criteria"
            />
          </Paragraph>
        </EmptyView>
      )}
    </>
  );
}

export function OrgStructurePaginationContainer({ orgId }: { orgId: string }) {
  const [rawQueryArgs] = useSearchParams();
  const { page, query } = orgStructureDeserializer(rawQueryArgs);
  const variables = { organizationId: orgId, offset: PAGE_SIZE * page, query };
  const { data, loading } = useQuery(CompanyOrgPaginatedQuery, { variables });

  const organization = data ? data.node! : null;
  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  if (loading || !organization) {
    return <LoadingIndicator className={Styles.loading} />;
  }

  return <OrgStructureWithPagination organization={organization} />;
}
