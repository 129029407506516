import classnames from "classnames";
import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { DescriptionList } from "common/core/description_list";
import Icon from "common/core/icon";

import Styles from "./index.module.scss";

export function DetailsDescriptionList({
  isTxnDetailsRedesign,
  children,
}: {
  isTxnDetailsRedesign: boolean;
  children: ReactNode;
}) {
  return isTxnDetailsRedesign ? (
    <DescriptionList className={Styles.descriptionListContainer}>{children}</DescriptionList>
  ) : (
    <>{children}</>
  );
}

export function PassFailStatus({
  dataAutomationId,
  status,
  customStatusText,
}: {
  dataAutomationId?: string;
  status: "pass" | "fail";
  customStatusText?: ReactNode;
}) {
  return (
    <div
      className={classnames(Styles.statusContainer, Styles[status])}
      data-automation-id={dataAutomationId}
    >
      {status === "pass" ? <Icon name="tick" /> : <Icon name="x-mark" />}
      {customStatusText ||
        (status === "pass" ? (
          <FormattedMessage id="65a5cc8a-23fa-462d-9fdb-eb22d716bc85" defaultMessage="Passed" />
        ) : (
          <FormattedMessage id="fc2d42fe-0744-45ef-a109-4c4c6a1cff39" defaultMessage="Failed" />
        ))}
    </div>
  );
}
