import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import { useMutation } from "util/graphql";
import { captureException } from "util/exception";
import Button from "common/core/button";
import { Card, CardSection } from "common/core/card";
import { usePermissions } from "common/core/current_user_role";
import { RadioLabel, RadioGroup, RadioInput } from "common/core/form/option";
import { useForm } from "common/core/form";
import { isAriaInvalid } from "common/core/form/error";
import { Container } from "common/core/responsive";
import { pushNotification } from "common/core/notification_center/actions";
import { SettingsTitle } from "common/settingsv2/common";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";
import { InvoiceProviderTypes, Payer } from "graphql_globals";
import { EasylinksPaidBy } from "common/settingsv2/sidebar_settings/billing/payment_settings/easylinks_paid_by";
import { CollabFeeSetting } from "common/settingsv2/lender_admin/collab_fee_setting";

import UpdateOrganizationPayerMutation from "./update_organization_payer_mutation.graphql";
import type { AdminCompanyDetailsOrganization_organization_Organization as Organization } from "../organization_query.graphql";

type FormValues = {
  defaultTransactionPayer: Payer;
};

const messages = defineMessages({
  defaultPayerTitle: {
    id: "673fa30f-3388-4308-81a3-58e991c3058a",
    defaultMessage: "Default transaction payer",
  },
  defaultPayerSubtitle: {
    id: "039d4264-16ad-43ac-9db4-af83c03e0a2a",
    defaultMessage:
      "If “Charge signer” is selected, signers are prompted for payment information when they complete each transaction.",
  },
  defaultPayerSigner: {
    id: "dbc68e1b-c625-4718-96d1-72c5a2da3a47",
    defaultMessage: "Charge signer",
  },
  defaultPayerOrganization: {
    id: "cee54562-829d-454f-b3bb-b363f14a34d1",
    defaultMessage: "Charge this organization",
  },
  saveChanges: {
    id: "53e707a8-599b-45b1-b6fe-e21eb4cfb1bd",
    defaultMessage: "Save changes",
  },
  saveSuccess: {
    id: "2fe54d6c-d891-4e9f-a09e-7925eb9a2c6f",
    defaultMessage: "Billing setup saved successfully.",
  },
  saveFailure: {
    id: "ecf3895e-d478-42c2-bf4d-c1e7360d8c8b",
    defaultMessage: "Error saving billing setup.",
  },
});

export function AdminCompanyPayerSettings(props: { organization: Organization }) {
  const {
    organization: { id, defaultPayer, defaultInvoiceProvider, defaultPaymentSource },
  } = props;
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const formDisabled = !hasPermissionFor("editCompanyDetailsBilling");

  const updateOrganizationPayerMutateFn = useMutation(UpdateOrganizationPayerMutation);
  const chargeablePaymentSource = Boolean(defaultPaymentSource?.last4); // Card or ACH
  const usesAutomaticPayments = !(
    defaultPayer === Payer.NOTARIZE ||
    defaultInvoiceProvider === InvoiceProviderTypes.NETSUITE ||
    (defaultPayer === Payer.CUSTOMER && !chargeablePaymentSource)
  );

  const form = useForm<FormValues>({
    defaultValues: {
      defaultTransactionPayer:
        defaultPayer === Payer.CUSTOMER ? Payer.CUSTOMER : Payer.ORGANIZATION,
    },
  });
  const { formState } = form;
  const handleSubmit = form.handleSubmit((data: FormValues) => {
    const { defaultTransactionPayer } = data;

    return updateOrganizationPayerMutateFn({
      variables: {
        input: {
          id,
          defaultPayer:
            defaultTransactionPayer === Payer.CUSTOMER
              ? Payer.CUSTOMER
              : usesAutomaticPayments
                ? Payer.ORGANIZATION
                : Payer.NOTARIZE,
        },
      },
    })
      .then(() => {
        pushNotification({
          message: intl.formatMessage(messages.saveSuccess),
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
        });
      })
      .catch((err) => {
        captureException(err);
        pushNotification({
          message: intl.formatMessage(messages.saveFailure),
          subtype: NOTIFICATION_SUBTYPES.ERROR,
        });
      });
  });

  return (
    <Container fluid style={{ width: "100%" }}>
      <SettingsTitle>
        <FormattedMessage
          id="f5659943-a16f-4b18-8ac3-232c93f47e93"
          defaultMessage="Payer settings"
        />
      </SettingsTitle>
      <Card
        footer={
          <Button
            type="submit"
            automationId="company-payer-form-submit"
            isLoading={formState.isSubmitting}
            disabled={formDisabled}
            buttonColor="action"
            variant="primary"
            onClick={handleSubmit}
          >
            {intl.formatMessage(messages.saveChanges)}
          </Button>
        }
      >
        <CardSection>
          <RadioGroup
            label={intl.formatMessage(messages.defaultPayerTitle)}
            helperText={intl.formatMessage(messages.defaultPayerSubtitle)}
            aria-invalid={isAriaInvalid(form.formState.errors.defaultTransactionPayer)}
          >
            <RadioLabel
              label={intl.formatMessage(messages.defaultPayerSigner)}
              radio={
                <RadioInput
                  value={Payer.CUSTOMER}
                  data-automation-id="default-transaction-payer-customer"
                  {...form.register("defaultTransactionPayer")}
                />
              }
            />
            <RadioLabel
              label={intl.formatMessage(messages.defaultPayerOrganization)}
              radio={
                <RadioInput
                  value={Payer.ORGANIZATION}
                  data-automation-id="default-transaction-payer-organization"
                  {...form.register("defaultTransactionPayer")}
                />
              }
            />
          </RadioGroup>
        </CardSection>
      </Card>
      <CollabFeeSetting />
      <EasylinksPaidBy />
    </Container>
  );
}
