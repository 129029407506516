import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { catchError, defer, exhaustMap, finalize, of, switchMap, tap, throwError } from "rxjs";

import { useMutation } from "util/graphql";
import { useLazyQuery } from "util/graphql/query";
import { useSubject } from "util/rxjs/hooks";
import { useActiveOrganization } from "common/account/active_organization";
import { captureException } from "util/exception";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";
import {
  PROOF_TRANSACTION_TYPE,
  ESIGN_TRANSACTION_TYPE,
  IDENTIFY_TRANSACTION_TYPE,
  VERIFY_TRANSACTION_TYPE,
} from "constants/transaction";
import type { CreateOrganizationTransactionV2Input } from "graphql_globals";

import DefaultAuthenticationRequirement, {
  type DefaultAuthenticationRequirement_organization_Organization as Organization,
} from "../index.query.graphql";
import CreateOrganizationTransactionV2, {
  type CreateOrganizationTransactionV2Variables as CreationVariables,
  type CreateOrganizationTransactionV2_createOrganizationTransactionV2_organizationTransaction as OrganizationTransaction,
} from "../create_organization_transaction_v2.mutation.graphql";

type TransactionCreationType =
  | typeof PROOF_TRANSACTION_TYPE
  | typeof ESIGN_TRANSACTION_TYPE
  | typeof IDENTIFY_TRANSACTION_TYPE
  | typeof VERIFY_TRANSACTION_TYPE;

type GetEditRouteArgs = {
  transactionType?: TransactionCreationType;
  transaction: OrganizationTransaction;
};

type GetErrorRouteArgs = {
  transactionType?: TransactionCreationType;
};

type GetInputVariablesArgs = {
  transactionType?: TransactionCreationType;
  organization: Organization;
};

type Props = {
  getEditRoute: (args: GetEditRouteArgs) => string;
  getErrorRoute?: (args: GetErrorRouteArgs) => string;
  getInputVariables: (
    args: GetInputVariablesArgs,
  ) => Omit<CreateOrganizationTransactionV2Input, "organizationId">;
};

export type GetEditRoute = Props["getEditRoute"];
export type GetErrorRoute = Props["getErrorRoute"];
export type GetInputVariables = Props["getInputVariables"];
export type { Organization };
export type RecipientInput = NonNullable<CreationVariables["input"]["customers"]>[number];

export type CreateTransactionReturnValue = ReturnType<
  ReturnType<typeof useCreateTransaction>["createTransaction"]
>;

type CreateArguments = { transactionType?: TransactionCreationType };

export function getTransactionTypeFromParam(
  typeParam: string | null,
): TransactionCreationType | undefined {
  if (
    typeParam === ESIGN_TRANSACTION_TYPE ||
    typeParam === PROOF_TRANSACTION_TYPE ||
    typeParam === IDENTIFY_TRANSACTION_TYPE ||
    typeParam === VERIFY_TRANSACTION_TYPE
  ) {
    return typeParam as TransactionCreationType;
  }

  return undefined;
}

export function useCreateTransaction({ getEditRoute, getErrorRoute, getInputVariables }: Props) {
  const navigate = useNavigate();
  const [activeOrganizationId] = useActiveOrganization();
  const [loading, setLoading] = useState(false);

  // These functions are stored in refs to avoid re-creating the subscription. Re-creating the subscription would
  // cause the previous subscription to be unsubscribed causing unintended side effects.
  const getEditRouteRef = useRef(getEditRoute);
  const getErrorRouteRef = useRef(getErrorRoute);
  const getInputVariablesRef = useRef(getInputVariables);

  // This is the main Subject we will subscribe to for creating transactions.
  const createTransactionInput$ = useSubject<CreateArguments | undefined>();

  // These are the actual GraphQL queries/mutations that are used inside the subscription Observable pipeline.
  // These are assumed to be stable by design but included in the dependency array to avoid future linting errors.
  const createTransactionMutateFn = useMutation(CreateOrganizationTransactionV2);
  const [getOrganizationData] = useLazyQuery(DefaultAuthenticationRequirement, {
    variables: { organizationId: activeOrganizationId! },
  });

  // Here we maintain the references to the functions so that they can be used inside the subscription Observable pipeline.
  // For example, if someone implements the following:
  //
  //  useCreateTransaction({ getEditRoute: () => "/edit" });
  //
  // and the component re-renders, we want the latest getEditRoute function to be used inside the subscription Observable pipeline
  // but not cancel the previous subscription. This is why we use useRef to store the functions.
  // Without this implementation detail, we would need to require users to pass in stable functions via useCallback, etc.
  useEffect(() => {
    getEditRouteRef.current = getEditRoute;
    getErrorRouteRef.current = getErrorRoute;
    getInputVariablesRef.current = getInputVariables;
  }, [getEditRoute, getErrorRoute, getInputVariables]);

  // This is where we create our subscription to our Subject. If this subscription is unsubscribed, the previous subscription
  // will be unsubscribed and the chain will cancel where it was.
  useEffect(() => {
    const sub = createTransactionInput$
      .pipe(
        // Set initial loading state
        tap(() => setLoading(true)),
        exhaustMap((input) => {
          const transactionType = input?.transactionType;

          // Create a new Observable that emits the result of getting default organization authentication requirements
          // and use this data to create a new transaction for that org.
          return defer(() => getOrganizationData()).pipe(
            switchMap(({ data }) => {
              const organization = data?.organization as Organization | undefined;

              if (!organization) {
                return throwError(() => new Error("Failed to fetch organization data"));
              }

              // Create a new Observable that emits the result of creating a new transaction. Here we use getInputVariablesRef
              // to get the latest getInputVariables function and pass it the organization data.
              return defer(() =>
                createTransactionMutateFn({
                  variables: {
                    input: {
                      organizationId: activeOrganizationId!,
                      ...getInputVariablesRef.current({ transactionType, organization }),
                    },
                  },
                }),
              ).pipe(
                switchMap(({ data }) => {
                  if (!data?.createOrganizationTransactionV2) {
                    return throwError(() => new Error("Empty or incomplete response data"));
                  }

                  // We return a new Observable that emits with a string of where to navigate at the completion of the
                  // pipeline chain.
                  return of(
                    getEditRouteRef.current({
                      transactionType,
                      transaction: data.createOrganizationTransactionV2.organizationTransaction,
                    }),
                  );
                }),
              );
            }),
            // We catch any errors here. This could be from either the getOrganizationData query or createTransactionMutateFn mutation
            catchError((error: unknown) => {
              captureException(error);
              pushNotification({
                message: (
                  <FormattedMessage
                    id="a496863b-8972-4a88-ae03-1d6bb67fa641"
                    defaultMessage="Failed to create transaction"
                  />
                ),
                subtype: NOTIFICATION_SUBTYPES.ERROR,
              });

              // When we get an error, we return a new Observable that emits with a string of where to navigate
              // if we encountered an error. This is optional and could return undefined.
              return of(getErrorRouteRef.current?.({ transactionType }));
            }),
            finalize(() => setLoading(false)),
          );
        }),
      )
      // We subscribe to the Observable pipeline here and navigate to the route that was emitted. This emission is from
      // either the success, in which case the result of getEditRouteRef, or the error, in which case the result of getErrorRouteRef.
      .subscribe((route) => {
        if (route) {
          navigate(route);
        }
      });

    return () => sub.unsubscribe();
  }, [activeOrganizationId, createTransactionMutateFn, getOrganizationData]);

  const createTransaction = useCallback((args?: CreateArguments) => {
    createTransactionInput$.next(args);
  }, []);

  return { createTransaction, loading };
}
