import {
  forwardRef,
  type ComponentPropsWithoutRef,
  type Ref,
  type KeyboardEvent,
  type ReactNode,
} from "react";
import classnames from "classnames";
import { defineMessages, type IntlShape } from "react-intl";

import RequiredAsterisk from "common/core/form/required-asterisk";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { useId } from "util/html";
import ActionButton from "common/core/action_button";
import Icon from "common/core/icon";

import { useAriaErrorDescribedId, type AriaRequired } from "../error";
import LayoutStyles from "../layout/index.module.scss";
import Styles from "./index.module.scss";
import TooltipOverlay from "../../tooltip/overlay";

type TextInputProps = AriaRequired<ComponentPropsWithoutRef<"input">>;
type TextAreaInputProps = AriaRequired<ComponentPropsWithoutRef<"textarea">> & {
  label?: string;
  displayRequiredAsterisk?: boolean;
};
type StyledTextInputProps = TextInputProps & {
  /** allows the placeholder to move into the label position if there is any user input */
  /** @deprecated using a placeholder as a label aka "floating labels" is discouraged for a11y reasons, we're moving away from this pattern and this prop will eventually be removed */
  placeholderAsLabel?: boolean;
  /** show a required asterisk as part of the placeholder */
  displayRequiredAsterisk?: boolean;
  /** Explicitly show a label element with the specified content, if used, placeholderAsLabel will be ignored */
  label?: string;
  /** Description text to be shown below the label */
  description?: string;
  /** Adds an 'x' icon to the left of the input field, clearableAction will be invoked onClick. 'value' prop is required for 'x' icon to disappear on blank value */
  clearableAction?: () => void;
  intl?: IntlShape;
  disabledHint?: string | ReactNode;
  disabledHintPlacement?: ComponentPropsWithoutRef<typeof TooltipOverlay>["placement"];
};

const MESSAGES = defineMessages({
  clearButton: {
    id: "b91456d2-a36a-4860-ab5f-566888b644a5",
    defaultMessage: "Clear input value",
  },
});

function preventDisabledKeyDown(e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
  // Allow for tabbing through the inputs still
  if (e.key !== "Tab" && !e.shiftKey) {
    e.preventDefault();
  }
}

function preventDisabledClipboard(e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
  e.preventDefault();
}

function TextInput(props: TextInputProps, ref: Ref<HTMLInputElement>) {
  const ariaDescribedBy = useAriaErrorDescribedId(props);
  const onKeyDownFn = props.disabled ? preventDisabledKeyDown : props.onKeyDown;
  const onPasteFn = props.disabled ? preventDisabledClipboard : props.onPaste;
  const onCutFn = props.disabled ? preventDisabledClipboard : props.onCut;

  return (
    <input
      autoComplete="off"
      type="text"
      {...props}
      aria-describedby={ariaDescribedBy}
      // To disable inputs, we want inputs to still be focusable but uneditable, so set
      // aria-disabled and overwrite the passed in disabled props to maintain focusability
      // and accessibility while preventing editing with preventDisabledKeyDown.
      aria-disabled={props.disabled}
      disabled={false}
      aria-label={props["aria-label"] || props.placeholder}
      onKeyDown={onKeyDownFn}
      onPaste={onPasteFn}
      onCut={onCutFn}
      ref={ref}
      className={classnames(
        LayoutStyles.inputContainer,
        Styles.text,
        SENSITIVE_CLASS,
        props.className,
      )}
      inputMode={props.disabled ? "none" : props.inputMode}
    />
  );
}
const TextInputWithRef = forwardRef(TextInput);

export const NativeLabel = ({
  label,
  description,
  htmlFor,
  displayRequiredAsterisk,
  disabled,
  ariaInvalid,
}: {
  label: string;
  description?: string;
  htmlFor: string;
  displayRequiredAsterisk?: boolean;
  disabled?: boolean;
  ariaInvalid?: string | boolean;
}) => {
  const invalid = ariaInvalid === true || ariaInvalid === "true";

  return (
    <label
      className={classnames(
        LayoutStyles.label,
        Styles.label,
        disabled && Styles.labelDisabled,
        invalid && LayoutStyles.invalid,
      )}
      htmlFor={htmlFor}
    >
      {label}
      {displayRequiredAsterisk && <RequiredAsterisk />}
      {description && <p className={Styles.inputDescription}>{description}</p>}
    </label>
  );
};

function StyledTextInput(props: StyledTextInputProps, ref: Ref<HTMLInputElement>) {
  const {
    className,
    placeholder,
    disabled,
    disabledHintPlacement = "top",
    clearableAction,
    intl,
    "aria-invalid": ariaInvalid,
    ...otherProps
  } = props;
  const id = useId();
  const ariaDescribedBy = useAriaErrorDescribedId(otherProps);
  const onKeyDownFn = disabled ? preventDisabledKeyDown : props.onKeyDown;
  const onPasteFn = disabled ? preventDisabledClipboard : props.onPaste;
  const onCutFn = disabled ? preventDisabledClipboard : props.onCut;
  const {
    label,
    description,
    displayRequiredAsterisk,
    placeholderAsLabel,
    disabledHint,
    ...inputProps
  } = props;
  // Placeholder = your typical placeholder
  // Label = the small header (eyebrow) embedded inside the input
  // If you use placeholderAsLabel, then the placeholder will
  // act as the label when the placeholder isn't itself needed
  // (e.g. if there's a value in the field)
  const placeholderAsLabelElement = placeholder ? (
    <label
      className={classnames("StyledText--placeholder", {
        hover: placeholderAsLabel,
        disabled,
      })}
      htmlFor={id}
    >
      {placeholder}
      {displayRequiredAsterisk && <RequiredAsterisk />}
    </label>
  ) : null;

  const nativeLabelElement = label ? (
    <NativeLabel
      {...{ label, description, displayRequiredAsterisk, disabled, htmlFor: id, ariaInvalid }}
    />
  ) : null;

  return (
    <div className={`StyledText ${SENSITIVE_CLASS}`}>
      {nativeLabelElement}
      <input
        type="text"
        {...inputProps}
        aria-describedby={ariaDescribedBy}
        aria-disabled={disabled}
        disabled={false}
        className={classnames("StyledText--input", Styles.text, className, {
          lower: !label && placeholder && placeholderAsLabel,
          disabled,
          [Styles.withLabel]: !!label,
        })}
        onKeyDown={onKeyDownFn}
        onPaste={onPasteFn}
        onCut={onCutFn}
        placeholder={placeholder}
        ref={ref}
        id={id}
        inputMode={disabled ? "none" : props.inputMode}
      />
      {disabledHint && disabled && (
        <TooltipOverlay trigger="hover" placement={disabledHintPlacement}>
          {disabledHint}
        </TooltipOverlay>
      )}

      {clearableAction && (
        <ActionButton
          automationId="clear-input-field"
          className={classnames(Styles.clearField, { [Styles.clearFieldHide]: !props.value })}
          onClick={clearableAction}
          aria-label={intl?.formatMessage(MESSAGES.clearButton)}
        >
          <Icon name="x-mark" />
        </ActionButton>
      )}
      {!label && (placeholderAsLabel || !props.value) && placeholderAsLabelElement}
    </div>
  );
}

function TextAreaInput(props: TextAreaInputProps, ref: Ref<HTMLTextAreaElement>) {
  const {
    "aria-invalid": ariaInvalid,
    disabled,
    label,
    displayRequiredAsterisk,
    "aria-label": ariaLabel,
    placeholder,
    className,
    onKeyDown,
  } = props;
  const ariaDescribedBy = useAriaErrorDescribedId(props);
  const onKeyDownFn = disabled ? preventDisabledKeyDown : onKeyDown;
  const onPasteFn = disabled ? preventDisabledClipboard : props.onPaste;
  const onCutFn = disabled ? preventDisabledClipboard : props.onCut;

  const id = useId();
  const nativeLabelElement = label ? (
    <NativeLabel {...{ label, displayRequiredAsterisk, disabled, htmlFor: id, ariaInvalid }} />
  ) : null;

  return (
    <div>
      {nativeLabelElement}
      <textarea
        autoComplete="off"
        id={label && id}
        {...props}
        aria-describedby={ariaDescribedBy}
        aria-disabled={disabled}
        disabled={false}
        aria-label={ariaLabel || placeholder}
        onKeyDown={onKeyDownFn}
        onPaste={onPasteFn}
        onCut={onCutFn}
        ref={ref}
        className={classnames(
          LayoutStyles.inputContainer,
          Styles.textarea,
          SENSITIVE_CLASS,
          className,
        )}
        inputMode={disabled ? "none" : props.inputMode}
      />
    </div>
  );
}

function EmailTextInput(props: Omit<TextInputProps, "type">, ref: Ref<HTMLInputElement>) {
  return (
    <TextInputWithRef {...props} ref={ref} type="email" data-automation-id="email-address-input" />
  );
}

function NumberInput(
  { inputMode = "decimal", ...props }: Omit<TextInputProps, "type">,
  ref: Ref<HTMLInputElement>,
) {
  // Note that this does _not_ have the `type="number"`. This is known to have accessibility problems, large
  // numbers are turned into exponents, accidental scrolling changes the number, devs have to deal with
  // `step` attribute etc.
  return <TextInputWithRef {...props} ref={ref} inputMode={inputMode} />;
}

const NumberInputWithRef = forwardRef(NumberInput);

function CurrencyInput(
  { inputMode = "decimal", ...props }: Omit<TextInputProps, "type">,
  ref: Ref<HTMLInputElement>,
) {
  return (
    <div className={classnames(Styles.currency, props.disabled && Styles.currencyDisabled)}>
      <NumberInputWithRef {...props} ref={ref} inputMode={inputMode} />
    </div>
  );
}

function DateInput(props: Omit<TextInputProps, "type">, ref: Ref<HTMLInputElement>) {
  return <TextInputWithRef {...props} ref={ref} type="date" />;
}

const EmailTextInputWithRef = forwardRef(EmailTextInput);
const StyledTextInputWithRef = forwardRef(StyledTextInput);
const TextAreaInputWithRef = forwardRef(TextAreaInput);
const DateInputWithRef = forwardRef(DateInput);
const CurrencyInputWithRef = forwardRef(CurrencyInput);

export {
  TextInputWithRef as TextInput,
  TextAreaInputWithRef as TextAreaInput,
  EmailTextInputWithRef as EmailTextInput,
  NumberInputWithRef as NumberInput,
  CurrencyInputWithRef as CurrencyInput,
  DateInputWithRef as DateInput,
  StyledTextInputWithRef as StyledTextInput,
};
